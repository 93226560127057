/* global angular:readonly, CookiesEuBanner:readonly, dataLayer:readonly, firebase:readonly, gtag:readonly, localforage:readonly */

"use strict";

((angular, CookiesEuBanner, dataLayer, gtag, localforage) => {
	new CookiesEuBanner(() => {
		gtag("consent", "update", {
			ad_storage: "granted",
			analytics_storage: "granted"
		});
	});

	localforage.config({
		name: "Direto dos Trens",
		storeName: "direto-dos-trens"
	});

	const defaultConfig = {
		"appengine_region_hostname": "direto-dos-trens.rj.r.appspot.com",
		"default_hostname": "www.diretodostrens.com.br",
		"lines": JSON.stringify({
			"1": "Azul",
			"2": "Verde",
			"3": "Vermelha",
			"4": "Amarela",
			"5": "Lilás",
			"6": "Laranja",
			"7": "Rubi",
			"8": "Diamante",
			"9": "Esmeralda",
			"10": "Turquesa",
			"11": "Coral",
			"12": "Safira",
			"13": "Jade",
			"15": "Prata",
			"17": "Ouro"
		}),
		"logo": "https://lh3.googleusercontent.com/jujusfARU24hTbzWoLG0AtSASRueQqeVjgOKCe2HukVgvcO3B4tvt1oCmJMqJH_j0TpxZIf6XWtcsGj583_8esEdg85wHj6inU--BVjbZLM=s3200",
		"privacy_policy": "https://static.diretodostrens.com.br/web/privacy-policy.html"
	};

	Object.freeze(defaultConfig);

	let appengineRegionHostname = defaultConfig.appengine_region_hostname;
	let defaultHostname = defaultConfig.default_hostname;
	let lines = JSON.parse(defaultConfig.lines);
	let logo = defaultConfig.logo;
	let privacy_policy = defaultConfig.privacy_policy;

	const firebaseConfig = {
		apiKey: "AIzaSyC1WM5Cmv1VZLvgBwOpbjj8L0fY4_Eail0",
		appId: "1:739011029096:web:c994d2f8cca0560f",
		authDomain: "firebase.diretodostrens.com.br",
		databaseURL: "https://direto-dos-trens.firebaseio.com",
		measurementId: "G-ZQ47HPSFHB",
		messagingSenderId: "739011029096",
		projectId: "direto-dos-trens",
		storageBucket: "direto-dos-trens.appspot.com"
	};

	Object.freeze(firebaseConfig);

	localforage.setItem("firebaseConfig", firebaseConfig);

	const firebaseApp = firebase.initializeApp(firebaseConfig);

	const firebaseAnalytics = firebaseApp.analytics();

	function initializeFundingChoices(property, firebaseAnalytics) {
		property.googlefc = property.googlefc || {};
		property.googlefc.ccpa = property.googlefc.ccpa || {};
		property.googlefc.callbackQueue = property.googlefc.callbackQueue || [];

		property.googlefc.callbackQueue.push({
			AD_BLOCK_DATA_READY: () => {
				const adBlockerStatus = property.googlefc.getAdBlockerStatus();
				const adBlockerStatusEnum = Object.freeze(property.googlefc.AdBlockerStatusEnum);

				firebaseAnalytics.logEvent(Object.keys(adBlockerStatusEnum).find(status => adBlockerStatusEnum[status] === adBlockerStatus), {
					event_category: "ad_blocker_status",
					non_interaction: true
				});

				const allowAdsStatus = property.googlefc.getAllowAdsStatus();
				const allowAdsStatusEnum = Object.freeze(property.googlefc.AllowAdsStatusEnum);

				firebaseAnalytics.logEvent(Object.keys(allowAdsStatusEnum).find(status => allowAdsStatusEnum[status] === allowAdsStatus), {
					event_category: "allow_ads_status",
					non_interaction: true
				});
			}
		});

		property.googlefc.callbackQueue.push({
			INITIAL_CCPA_DATA_READY: () => {
				const initialCcpaStatus = property.googlefc.ccpa.getInitialCcpaStatus();
				const initialCcpaStatusEnum = Object.freeze(property.googlefc.ccpa.InitialCcpaStatusEnum);

				firebaseAnalytics.logEvent(Object.keys(initialCcpaStatusEnum).find(status => initialCcpaStatusEnum[status] === initialCcpaStatus), {
					event_category: "initial_ccpa_status",
					non_interaction: true
				});
			}
		});
	}

	initializeFundingChoices(window, firebaseAnalytics);

	try {
		firebaseApp.performance();
	} catch (exception) {
		console.error(new Date().toISOString(), exception);
	}

	const firebaseRemoteConfig = firebaseApp.remoteConfig();

	firebaseRemoteConfig.defaultConfig = defaultConfig;

	firebaseRemoteConfig.fetchAndActivate().then(() => {
		appengineRegionHostname = firebaseRemoteConfig.getString("appengine_region_hostname");

		defaultHostname = firebaseRemoteConfig.getString("default_hostname");
		localforage.setItem("site", defaultHostname);

		lines = JSON.parse(firebaseRemoteConfig.getString("lines"));
		localforage.setItem("linhas", lines);

		logo = firebaseRemoteConfig.getString("logo");
		localforage.setItem("logo", logo);

		privacy_policy = firebaseRemoteConfig.getString("privacy_policy");
	});

	function codigoToLinha(codigo) {
		const linha = "Linha ";
		const nome = lines[codigo];
		if (nome) {
			return linha + codigo + "-" + nome;
		}
		return linha + codigo;
	}

	function getClassFromSituacao(situacao) {
		if (situacao) {
			const situacaoToLowerCase = situacao.toLowerCase();
			if (situacaoToLowerCase.includes("normal")) {
				return "success";
			}
			if (situacaoToLowerCase.includes("diferenciada") || situacaoToLowerCase.includes("parcial") || situacaoToLowerCase.includes("velocidade reduzida") || situacaoToLowerCase.includes("circulação de trens")) {
				return "warning";
			}
			if (situacaoToLowerCase.includes("paralisada")) {
				return "danger";
			}
			if (situacaoToLowerCase.includes("encerrada")) {
				return "info";
			}
			return "secondary";
		}
		return "dark";
	}

	const app = angular.module("direto-dos-trens", ["ngAnimate", "angular-loading-bar"]);

	app.config(($compileProvider, $locationProvider) => {
		$compileProvider.debugInfoEnabled(false);
		$locationProvider.html5Mode(true);
	});

	app.controller("main", ($filter, $http, $location, $scope, $window) => {
		const currentHostname = $location.host();

		if (currentHostname != "localhost" && currentHostname != defaultHostname) {
			const promises = [];
			if ("serviceWorker" in navigator) {
				navigator.serviceWorker.getRegistrations().then((registrations) => {
					for (const registration of registrations) {
						promises.push(registration.unregister());
					}
				});
			}
			Promise.all(promises).then(() => {
				location.href = $location.protocol() + "://" + defaultHostname + $location.url();
			});
		}

		let setTimeoutId;

		function reloadStatus(successCallback) {
			if (setTimeoutId) {
				clearTimeout(setTimeoutId);
				setTimeoutId = null;
			}
			if ($location.search().reset) {
				$scope.codigo = null;
				$scope.id = null;
				$scope.loaded = false;
				$scope.status = null;
				$scope.statusCodigo = null;
				localforage.clear().then(() => {
					setTimeout(() => {
						location.href = "/";
					}, 2 ** 10);
					successCallback();
				});
			} else if ($location.search().codigo) {
				let url = "/api/status/codigo/" + $location.search().codigo;
				const ano = $location.search().ano;
				const mes = $location.search().mes;
				const dia = $location.search().dia;
				if (ano && mes && dia) {
					url += "/" + ano + "/" + mes + "/" + dia;
				} else if (ano && mes) {
					url += "/" + ano + "/" + mes;
				} else if (ano) {
					url += "/" + ano;
				}
				$http.get(url).then((response) => {
					const data = response.data;
					const codigo = Number($location.search().codigo);
					$scope.codigo = codigo;
					$scope.id = null;
					$scope.loaded = true;
					$scope.status = null;
					$scope.statusCodigo = data;
					angular.element(document).ready(() => {
						const intersectionObservers = [];

						$scope.statusCodigo.forEach((status, index, array) => {
							function loadStatusId() {
								const url = "/api/status/id/" + status.id;
								$http.get(url).then((response) => {
									const data = response.data;
									array[index] = data;
									if (index == 0) {
										angular.element("meta[property=\"article:published_time\"]").attr("content", data.criado);
									}
									if (intersectionObservers[status.id]) {
										intersectionObservers[status.id].disconnect();
									}
								});
							}

							function intersectionObserverCallback(entries) {
								entries.forEach((entry) => {
									if (entry.intersectionRatio > 0) {
										loadStatusId();
									}
								});
							}

							if ("IntersectionObserver" in $window) {
								intersectionObservers[status.id] = new IntersectionObserver(intersectionObserverCallback);
								intersectionObservers[status.id].observe(angular.element("#" + status.id)[0]);
							} else {
								loadStatusId();
							}
						});
					});
					let title = "Direto dos Trens" + " | " + codigoToLinha(codigo);
					let description = "Histórico de alterações/incidentes reportados referentes à " + codigoToLinha(codigo);
					if (ano && mes && dia) {
						title += " | " + dia + "/" + mes + "/" + ano;
						description += " no dia " + dia + "/" + mes + "/" + ano;
					} else if (ano && mes) {
						title += " | " + mes + "/" + ano;
						description += " no mês " + mes + "/" + ano;
					} else if (ano) {
						title += " | " + ano;
						description += " no ano " + ano;
					}
					description += ".";
					angular.element("title").text(title);
					angular.element("meta[name=\"description\"]").attr("content", description);
					angular.element("meta[property=\"og:title\"]").attr("content", title);
					angular.element("meta[property=\"og:description\"]").attr("content", description);
					angular.element("meta[property=\"og:type\"]").attr("content", "website");
					successCallback();
				}, () => {
					$location.search({
						"codigo": null,
						"ano": null,
						"mes": null,
						"dia": null
					});
				});
			} else if ($location.search().id) {
				const url = "/api/status/id/" + $location.search().id;
				$http.get(url).then((response) => {
					const data = response.data;
					$scope.codigo = null;
					$scope.id = data;
					$scope.loaded = true;
					$scope.status = null;
					$scope.statusCodigo = null;
					const codigo = Number(data.codigo);
					const title = "Direto dos Trens" + " | " + codigoToLinha(codigo) + " | " + $filter("date")(data.criado, "dd/MM/yyyy HH:mm");
					const description = data.situacao + (data.descricao ? (" - " + data.descricao) : "");
					angular.element("title").text(title);
					angular.element("meta[name=\"description\"]").attr("content", description);
					angular.element("meta[property=\"og:title\"]").attr("content", title);
					angular.element("meta[property=\"og:description\"]").attr("content", description);
					angular.element("meta[property=\"og:type\"]").attr("content", "article");
					angular.element("meta[property=\"article:published_time\"]").attr("content", data.criado);
					successCallback();
				}, () => {
					$location.search("id", null);
				});
			} else {
				const url = "/api/status";
				$http.get(url).then((response) => {
					const data = response.data;
					$scope.codigo = null;
					$scope.id = null;
					$scope.loaded = true;
					$scope.status = data;
					$scope.statusCodigo = null;
					const title = "Direto dos Trens";
					const description = "Informações em tempo real, histórico de incidentes e notificações automáticas de alterações ocorridas nas linhas do Metrô e da CPTM.";
					angular.element("title").text(title);
					angular.element("meta[name=\"description\"]").attr("content", description);
					angular.element("meta[property=\"og:title\"]").attr("content", title);
					angular.element("meta[property=\"og:description\"]").attr("content", description);
					angular.element("meta[property=\"og:type\"]").attr("content", "website");
					angular.element("meta[property=\"article:published_time\"]").attr("content", (new Date()).toISOString());
					successCallback();
				});
				setTimeoutId = setTimeout(() => {
					reloadStatus(angular.noop);
				}, 2 ** 16);
			}
		}

		function scrollToTop() {
			$window.scrollTo(0, 0);
		}

		function toggleNotification(codigo) {
			localforage.getItem("notificacoes").then((value) => {
				if (!value) {
					value = [];
				}
				value[codigo] = !value[codigo];
				localforage.setItem("notificacoes", value).then((value) => {
					$scope.notificacoes = value;
					$scope.$apply();
				});
			});
		}

		reloadStatus(angular.noop);

		$scope.email = atob("bWFpbHRvOmNvbnRhdG9AZGlyZXRvZG9zdHJlbnMuY29tLmJy");
		$scope.privacy_policy = privacy_policy;

		localforage.getItem("notificacoes").then((value) => {
			if (!value) {
				value = [];
			}
			$scope.notificacoes = value;
		});

		const rangeOfYears = (start, end) => Array(end - start + 1).fill(start).map((year, index) => year + index);

		$scope.rangeOfYears = rangeOfYears(2017, (new Date()).getFullYear());

		$scope.codigoToLinha = codigoToLinha;
		$scope.getClassFromSituacao = getClassFromSituacao;
		$scope.scrollToTop = scrollToTop;
		$scope.toggleNotification = toggleNotification;

		$scope.$on("$locationChangeSuccess", (angularEvent, newUrl, oldUrl) => {
			if (newUrl && oldUrl && newUrl != oldUrl) {
				reloadStatus(angular.noop);
			}
		});

		if ((currentHostname == "localhost" || currentHostname == defaultHostname) && "serviceWorker" in navigator) {
			const serviceWorker = "/sw/index.min.js";

			navigator.serviceWorker.register(serviceWorker, { scope: "/" }).then((registration) => {
				function permissionGranted() {
					function registerToken(token, successCallback) {
						$http.post("/fcm/register", {
							token: token
						}).then(() => {
							localforage.setItem("tokenTimestamp", (new Date()).getTime()).then(() => {
								successCallback();
							});
						});
					}

					function unregisterToken(token, successCallback) {
						if (token) {
							$http.post("/fcm/unregister", {
								token: token
							}).then(successCallback());
						} else {
							successCallback();
						}
					}

					const firebaseMessaging = firebaseApp.messaging();

					firebaseMessaging.getToken({
						serviceWorkerRegistration: registration,
						vapidKey: "BHwgyF-z3jhb_19cqT53aiqh25Uv3OAvkEpvF1Tqh2ETqCcuRzlnA9sG_TjDwMgvD3mDvytYE862ekn4_e5aPeA"
					}).then((currentToken) => {
						$scope.token = currentToken;
						$scope.$apply();
						localforage.getItem("token").then((oldToken) => {
							if (oldToken != currentToken) {
								unregisterToken(oldToken, () => {
									registerToken(currentToken, () => {
										localforage.setItem("token", currentToken).then(() => {
											if ("indexedDB" in $window) {
												indexedDB.deleteDatabase("undefined");
											}
										});
									});
								});
							} else {
								localforage.getItem("tokenTimestamp").then((tokenTimestamp) => {
									if (!tokenTimestamp || (tokenTimestamp + (60 * 60 * 1000)) <= (new Date()).getTime()) {
										registerToken(currentToken, angular.noop);
									}
								});
							}
						});
					});

					firebaseMessaging.onMessage((payload) => {
						if (payload.data && payload.data.status) {
							const status = JSON.parse(payload.data.status);
							Object.freeze(status);
							if (status) {
								localforage.getItem("notificacoes").then((value) => {
									if (!value || !value[status.codigo]) {
										const notification = new Notification(codigoToLinha(status.codigo), {
											body: status.situacao + (status.descricao ? " - " + status.descricao : ""),
											data: status,
											icon: logo
										});
										notification.onclick = (event) => {
											event.preventDefault();
											if (status.url) {
												open(status.url, "_blank");
											} else {
												open("https://" + defaultHostname + "/?id=" + status.id, "_blank");
											}
											notification.close();
										}
										setTimeout(() => {
											notification.close();
										}, 3 * 60 * 60 * 1000);
									}
								});
							}
						}
					});
				}

				if ("Notification" in $window) {
					function notificationRequestPermission() {
						Notification.requestPermission().then((permission) => {
							if (permission == "granted") {
								$scope.notificationAlert = false;
								permissionGranted();
							}
						});
					}

					$scope.notificationRequestPermission = notificationRequestPermission;

					if (Notification.permission == "granted") {
						$scope.notificationAlert = false;
						permissionGranted();
					} else if (Notification.permission != "denied") {
						$scope.notificationAlert = true;
						notificationRequestPermission();
					}
				}
			});
		}
	});
})(angular, CookiesEuBanner, dataLayer, gtag, localforage);
